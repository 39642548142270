import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCouch,
  faHome,
  faTree,
  faThermometerHalf,
} from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';
import CustomSlider from '../components/customSlider';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const DogBoarding = ({ data }) => {
  var pageTitle = 'Dog Boarding';

  return (
    <Layout
      slug="dog-boarding"
      title={pageTitle}
      description="Find out more about the variety of dog boarding options avaiable!"
    >
      <h1>{pageTitle}</h1>

      <p className="text-xl">
        At Very Important Pets we understand that every dog is different so we
        have a vast selection of options to ensure we are able to cater to your
        canine's individual needs.
      </p>
      <Link className="btn btn-light mb-4" to="/what-to-bring/">
        What to Bring
      </Link>

      <div
        className="w-full flex flex-col lg:flex-row gap-6 mb-10 rounded-2xl overflow-hidden"
        style={{ backgroundColor: '#5e7ce2ff' }}
      >
        <div className="w-full lg:w-1/2 px-5 pt-5 lg:pb-5 text-white">
          <h2 className="h2">Standard Dog Suites</h2>
          <span className="inline-block text-base font-semibold mb-2">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 24-30 sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 56 sq. ft.
            </span>
          </span>
          <p>
            Standard suites are great for small/medium dogs or we also have some
            with bigger doors for large dogs as well. Dogs boarding in our
            standard suites get daily turns in our XL play yards to roam, sniff
            and play!
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          <CustomSlider images={data.standard.nodes} minHeight={400} />
        </div>
      </div>

      <div
        className="w-full flex flex-col lg:flex-row gap-6 mb-10 rounded-2xl overflow-hidden"
        style={{ backgroundColor: '#fe6d73ff' }}
      >
        <div className="w-full lg:w-1/2 px-5 pt-5 lg:pb-5 text-white">
          <h2 className="h2">Large Suites</h2>
          <span className="inline-block text-base font-semibold mb-2">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 45-60 sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 165 sq. ft.
            </span>
          </span>
          <p>
            Large and family suites are a great option for large/giant dogs who
            may need a little more space. Theses suites also have XL doors to
            ensure that even the biggest pups out there are able to get outside
            with ease. They are also a good option for dogs who are staying with
            us for extended periods. Dogs boarding in our large suites get daily
            turns in our XL play yards to roam, sniff and play!
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          <CustomSlider images={data.large.nodes} minHeight={400} />
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row gap-6 mb-10 rounded-2xl overflow-hidden bg-vip-purple">
        <div className="w-full lg:w-1/2 px-5 pt-5 lg:pb-5 text-white">
          <h2 className="h2">Family Suites</h2>
          <span className="inline-block text-base font-semibold mb-2">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 45-60 sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 165 sq. ft.
            </span>
          </span>
          <p>
            Family suites are a great option for families with multiple dogs who
            get along well and need a little more space. They are also a good
            option for dogs who are staying with us for extended periods. Family
            suites also include a toddler bed for increased comfort which also
            makes this a great option for older dogs that benefit from having
            raised bedding options! .Dogs boarding in our family suites get
            daily turns in our XL play yards to roam, sniff and play!
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          <CustomSlider images={data.family.nodes} minHeight={400} />
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row gap-6 mb-10 rounded-2xl overflow-hidden bg-vip-passion">
        <div className="w-full lg:w-1/2 px-5 pt-5 lg:pb-5">
          <h2 className="h2">Small Dog Luxury Suites</h2>
          <span className="inline-block text-base font-semibold mb-2">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 30+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 50+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faCouch} className="inline-icon" />
              Furnished
            </span>
          </span>
          <p>
            Our small dog luxury suites are private rooms which include extra
            amenities such as a miniature leather couch and pet safe fireplace.
            These are a great option for dogs who may be unsure around others
            and would do well with a more private area or for pets who need a
            little extra spoiling! They are also great for dogs who may be a
            little older and need a quieter, calmer area or for dogs who aren't
            as used to being boarded. The outdoor area lets out onto the
            opposite side of the building as the other dogs which reinforces the
            quiet,calm environment.
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          <CustomSlider images={data.small_luxury.nodes} minHeight={400} />
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row gap-6 mb-10 rounded-2xl overflow-hidden bg-vip-turquoise">
        <div className="w-full lg:w-1/2 px-5 pt-5 lg:pb-5">
          <h2 className="h2">Luxury Suites</h2>
          <span className="inline-block text-base font-semibold mb-2">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 100+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 280+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faCouch} className="inline-icon" />
              Furnished
            </span>
          </span>
          <p>
            Our luxury suites are private rooms which include extra amenities
            such as a toddler bed and a pet-safe fireplace. These are a great
            option for dogs who may be unsure around others and would do well
            with their own private area or for pets who need a little extra
            spoiling! They are also great for dogs who are staying with us for
            an extended period of time or families who have large dogs or
            multiple canine friends. Dogs boarding in our luxury suites get
            daily turns in our XL play yards to roam, sniff and play!
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          <CustomSlider images={data.luxury.nodes} minHeight={400} />
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row gap-6 mb-10 rounded-2xl overflow-hidden bg-vip-passion">
        <div className="w-full lg:w-1/2 px-5 pt-5 lg:pb-5">
          <h2 className="h2">Pawsh Suite</h2>
          <span className="inline-block text-base font-semibold mb-2">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 300+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 600+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faCouch} className="inline-icon" />
              Furnished
            </span>
          </span>
          <p className="m-0">
            Our Pawsh suite is a private room which includes extra amenities
            such as couches and a pet-safe fireplace. This is a great option for
            dogs who may be unsure around others and would do well with their
            own private area or for pets who need a little extra spoiling! The
            room is designed to be a cozy living room style environment to help
            your pet feel at home. Due to it's large size, this is great for
            dogs who are staying with us for an extended period of time or
            families who have large dogs or multiple canine friends. Dogs
            boarding in our Pawsh suites get their own XL play yard to roam,
            sniff and play!
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          <CustomSlider images={data.pawsh.nodes} minHeight={400} />
        </div>
      </div>

      {/* <div className="w-full flex flex-col md:flex-row mb-4 py-2 p-2 rounded-2xl bg-vip-turquoise">
        <div
          className="w-full md:w-2/3 flex flex-col md:flex-row py-2 p-2 rounded-2xl"
          style={{ backgroundColor: '#fe6d73ff' }}
        >
          <div
            className="w-full md:w-1/2 flex flex-col md:flex-row rounded-2xl"
            style={{ backgroundColor: '#5e7ce2ff' }}
          >
            <div className="text-white py-2 px-4">
              <h2 className="h3">Standard Dog Suites</h2>
              <span className="inline-block text-base font-semibold mb-2">
                <span className="relative pl-6">
                  <FontAwesomeIcon icon={faHome} className="inline-icon" />
                  Inside: 24-30 sq. ft.
                </span>
                <br />
                <span className="relative pl-6">
                  <FontAwesomeIcon
                    icon={faThermometerHalf}
                    className="inline-icon"
                  />
                  Heated and air-conditioned
                </span>
                <br />
                <span className="relative pl-6">
                  <FontAwesomeIcon icon={faTree} className="inline-icon" />
                  Outside: 56 sq. ft.
                </span>
              </span>
              <p>
                Standard suites are great for small/medium dogs or for large
                dogs who are staying with us for a shorter period of time.
              </p>
            </div>
          </div>

          <div className="text-white w-full md:w-1/2 py-2 pl-4 pr-2">
            <h2 className="h3">Large/Family Suites</h2>
            <span className="inline-block text-base font-semibold mb-2">
              <span className="relative pl-6">
                <FontAwesomeIcon icon={faHome} className="inline-icon" />
                Inside: 45-60 sq. ft.
              </span>
              <br />
              <span className="relative pl-6">
                <FontAwesomeIcon
                  icon={faThermometerHalf}
                  className="inline-icon"
                />
                Heated and air-conditioned
              </span>
              <br />
              <span className="relative pl-6">
                <FontAwesomeIcon icon={faTree} className="inline-icon" />
                Outside: 165 sq. ft.
              </span>
            </span>
            <p>
              Large and family suites are a great option for families with
              multiple dogs who get along well or for large/giant dogs who may
              need a little more space. They are also a good option for dogs who
              are staying with us for extended periods.
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/3 py-4 pl-4 pr-2">
          <h2 className="h3">Luxury Suites</h2>
          <span className="inline-block text-base font-semibold mb-2">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 100+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 280+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faCouch} className="inline-icon" />
              Furnished
            </span>
          </span>
          <p>
            Our luxury suites are private rooms which include extra amenities
            such as a couch and a pet-safe fireplace. These are a great option
            for dogs who may be unsure around others and would do well with
            their own private area or for pets who need a little extra spoiling!
            They are also great for dogs who are staying with us for an extended
            period of time or families who have large dogs or multiple canine
            friends.
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col mb-4 p-4 pt-2 rounded-2xl bg-vip-passion">
        <h2 className="h3">Pawsh Suite</h2>
        <div className="flex flex-col md:flex-row">
          <span className="inline-block text-base font-semibold whitespace-nowrap mb-2 md:mb-0 md:mr-4">
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faHome} className="inline-icon" />
              Inside: 300+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon
                icon={faThermometerHalf}
                className="inline-icon"
              />
              Heated and air-conditioned
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faTree} className="inline-icon" />
              Outside: 600+ sq. ft.
            </span>
            <br />
            <span className="relative pl-6">
              <FontAwesomeIcon icon={faCouch} className="inline-icon" />
              Furnished
            </span>
          </span>
          <p className="m-0">
            Our Pawsh suite is a private room which includes extra amenities
            such as couches and a pet-safe fireplace. This is a great option for
            dogs who may be unsure around others and would do well with their
            own private area or for pets who need a little extra spoiling! The
            room is designed to be a cozy living room style environment to help
            your pet feel at home. Due to it's large size, this is great for
            dogs who are staying with us for an extended period of time or
            families who have large dogs or multiple canine friends.
          </p>
        </div>
      </div> */}

      <h2>Outdoor Dog Kennels</h2>
      <p>
        In the summer outdoor dogs can enjoy our air conditioning like everyone
        else; however, we understand how important it is for them to stay in a
        temperature similar to what they are used to at home during the winter.
        This is why we have kennels set up to accommodate this need. If it is
        bitterly cold out we are still able to adjust the temperature for a more
        comfortable winter temperature for them. Our dog doors and large play
        yards also give large outside farm dogs the freedom and space they are
        used to at home.
      </p>

      <h2>30,000 sq. ft. of Play Yards</h2>
      <p>
        With our newest expansion, we have over 30,000 sq. ft. of outdoor play
        yards. All dogs boarding with us will get daily turns in our large
        exercise yards. During your pets turn in the yard we interact and play
        with them. Dogs who are with us for over 5 days also have the option to
        register into our play and stay program which allows them supervised
        playtime with other dogs of similar size and play style. Learn more
        about our play and stay programs on our{' '}
        <Link to="/daycare/">daycare page</Link>.
      </p>
    </Layout>
  );
};

export const query = graphql`
  query {
    standard: allFile(
      filter: { relativeDirectory: { eq: "gallery/standard" } }
    ) {
      ...CustomSliderImage
    }
    large: allFile(filter: { relativeDirectory: { eq: "gallery/large" } }) {
      ...CustomSliderImage
    }
    family: allFile(filter: { relativeDirectory: { eq: "gallery/family" } }) {
      ...CustomSliderImage
    }
    luxury: allFile(filter: { relativeDirectory: { eq: "gallery/luxury" } }) {
      ...CustomSliderImage
    }
    pawsh: allFile(filter: { relativeDirectory: { eq: "gallery/pawsh" } }) {
      ...CustomSliderImage
    }
    small_luxury: allFile(
      filter: { relativeDirectory: { eq: "gallery/small_luxury" } }
    ) {
      ...CustomSliderImage
    }
  }
`;

export default DogBoarding;
