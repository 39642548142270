import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCompress,
  faExpand,
} from '@fortawesome/free-solid-svg-icons';

const CustomSlider = ({
  images,
  wrapperStyle,
  minHeight,
  maxHeight,
  showThumbnails = false,
  thumbnailSize = 70,
  showBullets = true,
  hideAtEnd = true,
}) => {
  const thumbnailsRef = useRef();
  const [currentImg, setCurrentImg] = useState(0);
  const [isFullscreen, setFullscreen] = useState(false);

  const isFirst = currentImg === 0;
  const isLast = currentImg === images.length - 1;

  const handlePrevious = () => {
    if (currentImg > 0) {
      let previous = currentImg - 1;
      if (showThumbnails) {
        thumbnailIntoView(getThumbnailElem(previous));
      }
      setCurrentImg(previous);
    }
  };

  const handleNext = () => {
    if (currentImg < images.length - 1) {
      let next = currentImg + 1;
      if (showThumbnails) {
        thumbnailIntoView(getThumbnailElem(next));
      }
      setCurrentImg(next);
    }
  };

  const handleFullscreen = () => {
    setFullscreen(!isFullscreen);
  };

  const getThumbnailElem = index => {
    let thumbnails = thumbnailsRef.current.querySelectorAll('button');

    return thumbnails[index];
  };

  const thumbnailIntoView = thumbnail => {
    let wrapper = thumbnailsRef.current;
    let wrapperRect = wrapper.getBoundingClientRect();
    let thumbnailRect = thumbnail.getBoundingClientRect();

    if (!isVisible(thumbnail, wrapper)) {
      if (thumbnailRect.left >= wrapperRect.left) {
        wrapper.scrollLeft =
          thumbnail.offsetLeft + thumbnailRect.width - wrapperRect.width;
      } else {
        wrapper.scrollLeft = thumbnail.offsetLeft;
      }
    }
  };

  return (
    <div
      className={classNames(
        isFullscreen ? 'fixed z-50 bg-black' : '',
        'flex flex-col'
      )}
      style={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        minHeight: minHeight,
        height: !isFullscreen ? (minHeight ? '100%' : 500) : '100vh',
        // transitionProperty:
        //   'background-color, height, width, top, left, bottom, right',
        // transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        // transitionDuration: '1000ms',
      }}
    >
      <div
        className={classNames(
          'relative flex-grow',
          showThumbnails ? 'mb-4' : ''
        )}
      >
        <GatsbyImage
          image={getImage(images[currentImg].original)}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          objectFit="contain"
          alt="image slider"
        />
        {((!isFirst && hideAtEnd) || !hideAtEnd) && (
          <button
            className={classNames(
              'slider-btn btn-dark absolute flex items-center focus:outline-none ml-3 transform -translate-y-1/2',
              isFirst ? 'cursor-not-allowed' : '',
              isFullscreen ? '!px-3 !py-2' : ''
            )}
            style={{ left: 0, top: '50%' }}
            onClick={handlePrevious}
            disabled={isFirst}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={classNames(
                'text-white ml-1 mr-1',
                isFullscreen ? 'text-4xl' : ''
              )}
            />
            <span className="sr-only">Previous</span>
          </button>
        )}
        {((!isLast && hideAtEnd) || !hideAtEnd) && (
          <button
            className={classNames(
              isLast ? 'cursor-not-allowed' : '',
              'slider-btn btn-dark absolute flex items-center focus:outline-none mr-3 transform -translate-y-1/2'
            )}
            style={{ right: 0, top: '50%' }}
            onClick={handleNext}
            disabled={isLast}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={classNames(
                'text-white ml-1 mr-1',
                isFullscreen ? 'text-4xl' : ''
              )}
            />
            <span className="sr-only">Next</span>
          </button>
        )}

        <button
          className={classNames(
            'absolute top-0 right-0 mt-3 mr-3 rounded-md bg-white focus:outline-none',
            isFullscreen ? 'p-3' : 'p-2'
          )}
          onClick={handleFullscreen}
        >
          <FontAwesomeIcon
            icon={!isFullscreen ? faExpand : faCompress}
            className={classNames(
              'flex align-center text-black',
              isFullscreen ? 'text-2xl' : ''
            )}
          />
          <span className="sr-only">
            {!isFullscreen ? 'Make Fullscreen' : 'Exit Fullscreen'}
          </span>
        </button>

        {showBullets && (
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-3 mb-4">
            {images.map((_, i) => (
              <button
                key={i}
                className={classNames(
                  currentImg === i ? 'bg-vip-turquoise' : 'bg-gray-200',
                  'border-2 border-gray-500 rounded-full focus:outline-none'
                )}
                style={{ width: 12, height: 12 }}
                onClick={() => {
                  setCurrentImg(i);
                }}
              >
                <span className="sr-only">Show #{i + 1} image</span>
              </button>
            ))}
          </div>
        )}
      </div>
      {showThumbnails && (
        <div
          ref={thumbnailsRef}
          className={classNames(
            'relative flex flex-row gap-2 overflow-x-auto',
            isFullscreen ? 'mb-3' : ''
          )}
          style={{ height: 'min-content', scrollBehavior: 'smooth' }}
        >
          {images.map((img, i) => (
            <button
              key={i}
              className={classNames(
                'rounded-md focus:outline-none overflow-hidden',
                currentImg === i
                  ? 'border-3 border-vip-turquoise'
                  : 'border-3 border-transparent'
              )}
              style={{ minWidth: thumbnailSize, minHeight: thumbnailSize }}
              onClick={() => {
                setCurrentImg(i);
              }}
            >
              <GatsbyImage
                image={getImage(img.thumbnail)}
                alt="slider image thumbnail"
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSlider;

export const query = graphql`
  fragment CustomSliderImage on FileConnection {
    nodes {
      thumbnail: childImageSharp {
        gatsbyImageData(
          width: 100
          height: 100
          placeholder: BLURRED
          quality: 30
          transformOptions: { cropFocus: ENTROPY }
        )
      }
      original: childImageSharp {
        gatsbyImageData(width: 800, placeholder: BLURRED)
      }
    }
  }
`;

const isVisible = (element, container) => {
  let elementRect = element.getBoundingClientRect();
  let containerRect = container.getBoundingClientRect();

  return (
    elementRect.top >= containerRect.top &&
    elementRect.left >= containerRect.left &&
    elementRect.bottom <= containerRect.bottom &&
    elementRect.right <= containerRect.right
  );
};
